/* =======================================================================
## ++ Media Queries
========================================================================== */

@mixin for-size($range) {
  $phone-upper-boundary: 600px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) { @content; }
  } @else if $range == tablet-portrait {
    @media (min-width: $phone-upper-boundary) { @content; }
  } @else if $range == tablet-landscape {
    @media (min-width: $tablet-portrait-upper-boundary) { @content; }
  } @else if $range == desktop {
    @media (min-width: $tablet-landscape-upper-boundary) { @content; }
  } @else if $range == big-desktop {
    @media (min-width: $desktop-upper-boundary) { @content; }
  }
}

/* =======================================================================
## ++ Unit transform
========================================================================== */

/*  
    Used for making containers have width in percentages. 
    Usage: define elemnt width in px and the width of parent elemnt in px. 
    eg. .block{width:cp(512px, 1024px)} this will result in .block{width:50%;}   
*/

@function cp($target, $container) {
  @return ($target / $container) * 100%;
}

/*  
    Used for making px values convert to rem values
    Usage: define font-size in px and it will convert to rems
    eg. font-size: rem(14px);
*/

@function rem($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0
  }
  @return $target / $context + 0rem;
}

$base-font-size: 16px;