/* =======================================================================
## ++ Globals
========================================================================== */
body {
  -webkit-font-smoothing: antialiased;
  background-color: $color-background;
  color: #000000;
  font-size: 100%;
  font-family: 'Lato', sans-serif;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  color: $color-primary;
}

h1 {

  @include for-size(phone-only) {
    font-size: 28px;
    margin: 10px 0;
  }

  @include for-size(tablet-portrait) {
    font-size: 26px;
    margin: 10px 0;
  }

  @include for-size(tablet-landscape) {
    font-size: 24px;
  }

  @include for-size(desktop) {
    font-size: 22px;
  }
}

h2 {

  @include for-size(phone-only) {
    font-size: 24px;
    margin: 10px 0;
  }

  @include for-size(tablet-portrait) {
    font-size: 28px;
    margin: 10px 0;
  }

  @include for-size(tablet-landscape) {
    font-size: 24px;
  }

  @include for-size(desktop) {
    font-size: 28px;
  }
}

p {

  @include for-size(phone-only) {
    font-size: 16px;
  }

  @include for-size(tablet-portrait) {
    font-size: 12px;
  }

  @include for-size(tablet-landscape) {
    font-size: 16px;
  }

  @include for-size(desktop) {
    font-size: 16px;
  }
}

.inside {
  max-width: 1366px;
  margin: auto;

  @include for-size(phone-only) {
    padding: 0 5px;
  }

  @include for-size(tablet-portrait) {
    padding: 0 5px;
  }

  @include for-size(tablet-landscape) {
    padding: 0 10px;
  }

  @include for-size(desktop) {
    padding: 0 10px;
  }
}


/* =======================================================================
## ++ Clearfix
========================================================================== */

/* float clearing for IE6 */
* html .clearfix {
  height: 1%;
  overflow: visible;
}

/* float clearing for IE7 */
* + html .clearfix {
  min-height: 1%;
}

/* float clearing for everyone else */
.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  font-size: 0;
}

.clr {
  clear: both;
}

// Todo: Move this
.social-bar {
  margin: 20px 0;

  @include for-size(phone-only) {
    padding: 5px;
  }

  @include for-size(tablet-portrait) {
    padding: 10px;
  }

  @include for-size(tablet-landscape) {
    padding: 10px 0;
  }

  .social-bar__title {
    font-size: 20px;
    margin: 20px 0;
  }

  .social-bar__items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    @include for-size(phone-only) {
      flex-direction: column;
    }
  }

  .social-bar__items__item {
    padding: 30px 20px;
    text-align: center;
    width: 20%;
    opacity: 0.9;
    border-radius: 10px;
    text-decoration: none;

    @include for-size(phone-only) {
      background-color: #e0e0e0;
      opacity: 1;
      width: 90%;
      margin: 10px auto;

      &.facebook {

        > * {
          color: $color-facebook;
        }

        h4 {
          border-bottom: 2px solid $color-facebook;
          opacity: 1;
        }
      }

      &.instagram {

        > * {
          color: $color-instagram;
        }

        h4 {
          border-bottom: 2px solid $color-instagram;
          opacity: 1;
        }
      }

      &.linkedin {

        > * {
          color: $color-linked-in;
        }

        h4 {
          border-bottom: 2px solid $color-linked-in;
          opacity: 1;
        }
      }
    }

    &:hover {
      background-color: #e0e0e0;
      cursor: pointer;
      opacity: 1;
      transition: all 0.3s ease;
    }

    &.facebook:hover, &.facebook:focus {

      > * {
        color: $color-facebook;
      }

      h4 {
        border-bottom: 2px solid $color-facebook;
        opacity: 1;
      }
    }

    &.instagram:hover {

      > * {
        color: $color-instagram;
      }

      h4 {
        border-bottom: 2px solid $color-instagram;
        opacity: 1;
      }
    }

    &.linkedin:hover {

      > * {
        color: $color-linked-in;
      }

      h4 {
        border-bottom: 2px solid $color-linked-in;
        opacity: 1;
      }
    }

    i {
      font-size: 60px;
      margin-bottom: 20px;
      color: #aaaaaa;
    }

    h4 {
      color: #aaaaaa;
      opacity: 0.5;
      display: inline-block;
      margin-left: 10px;
      font-weight: 600;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 40px;

  @include for-size(phone-only) {
    flex-direction: column;
  }

  p {
    margin-bottom: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 20px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    color: black;
    font-size: 18px;
  }

  .flex-row__item {
    flex: 0 1 auto;
    justify-content: center;
    padding: 20px;

    @include for-size(phone-only) {
      padding: 10px;
    }

    &.one-half {
      width: 50%;

      @include for-size(phone-only) {
        width: 100%;
      }
    }

    &.one-third {
      width: 32%;

      @include for-size(phone-only) {
        width: 100%;
      }
    }

    &.two-third {
      width: 65%;

      @include for-size(phone-only) {
        width: 100%;
      }
    }

    img {
      max-height: 100%;
      max-width: 100%;
      vertical-align: center;
    }
  }
}

article {
  .content {

    @include for-size(phone-only) {
      padding: 5px;
    }

    @include for-size(tablet-portrait) {
      padding: 10px;
    }

    @include for-size(tablet-landscape) {
      padding: 10px 0;
    }
  }
}

.datagrid table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.table-title {
  margin: 20px 0;
}

h3.table-info {
  font-size: 14px;
  font-style: italic;
  color: #5a5a5a;
  margin-bottom: 40px;
  margin-left: 5px;

  .fa {
    color: $color-dark-background;
    font-size: 16px;
  }
}

.datagrid {
  background: $color-light-text;
  margin: 10px 0;
  border: 1px solid $color-primary;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.datagrid table td, .datagrid table th {
  padding: 3px 10px;
}

.datagrid table thead th {
  background-color: #374998;
  color: #F8F8F8;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  border-left: 1px solid #A3A3A3;
}

.datagrid table thead th:first-child {
  border: none;
}

.datagrid table tbody td {
  color: #5e6063;
  border-left: 1px solid #DBDBDB;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}

.datagrid table tbody .alt td {
  background: #EBEBEB;
}

.datagrid table tbody td:first-child {
  border-left: none;
}

.datagrid table tbody tr:last-child td {
  border-bottom: none;
}