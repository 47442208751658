article {

  .slider {
    margin-bottom: 40px !important;

    .slick-track {
      height: 600px;

      @include for-size(phone-only) {
        height: 300px;
      }
    }

    .slider-item {
      height: 600px;
      background-size: cover;
      background-position: center;

      @include for-size(phone-only) {
        height: 300px;
      }
    }

    .slick-dots {
      bottom: 20px;

      li {

        &.slick-active {
          opacity: 1.0;
        }

        button:before {
          color: $color-primary;
          font-size: 14px;
        }
      }
    }
  }

  .carousel-edit {

    .pimcore_editable {
      width: 260px;
      display: inline-block;
      float: left;

      img {
        width: 250px;
      }
    }

  }
}