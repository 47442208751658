body.sticky-header {

  header {
    position: fixed;
    top: 0;
    z-index: 900;
    width: 100%;
    background-color: #e4e4e4;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s ease;
  }
}

header {

  .inside {

    @include for-size(phone-only) {
      height: 50px;
    }

    @include for-size(tablet-portrait) {
      height: 50px;
    }

    @include for-size(tablet-landscape) {
      height: 70px;
    }
  }

  .site-branding {

    .site-branding__logo {
      float: left;
      height: 70px;

      @include for-size(phone-only) {
        height: 50px;
        padding: 5px 0;
      }

      @include for-size(tablet-portrait) {
        height: 50px;
        padding: 5px 0;
      }

      @include for-size(tablet-landscape) {
        height: 70px;
        padding: 10px 0;
      }
    }

    .site-branding__name {
      float: left;
      font-family: 'Cormorant', sans-serif;
      font-weight: 500;
      margin: 0;
      margin-left: 5px;

      @include for-size(phone-only) {
        line-height: 50px;
        font-size: 24px;
      }

      @include for-size(tablet-portrait) {
        line-height: 50px;
        font-size: 24px;
      }

      @include for-size(tablet-landscape) {
        line-height: 70px;
        padding-left: 5px;
        font-size: 38px;
      }

      @include for-size(desktop) {
        line-height: 70px;
        padding-left: 5px;
        font-size: 44px;
      }
    }
  }

  .site-menu {

    .site-menu__main {
      float: right;
      padding: 0 5px;

      ul {
        margin: 0;
        list-style: none;

        li {
          display: inline-block;
          margin-left: 10px;
          font-weight: 400;
          line-height: 40px;

          @include for-size(phone-only) {
            font-size: 14px;
            line-height: 50px;
          }

          @include for-size(tablet-portrait) {
            font-size: 14px;
            line-height: 50px;
          }

          @include for-size(tablet-landscape) {
            font-size: 20px;
            line-height: 70px;
          }

          a {
              text-decoration: none;
              color: $color-primary;

              &:hover {
                  color: $color-highlight;
              }
          }
        }
      }

      @include for-size(phone-only) {
        display: none;
      }
    }

    .site-menu__side {
      float: right;

      .container {
        display: none;
        position: relative;
        height: 25px;
        user-select: none;

        span {
          display: block;
          width: 33px;
          height: 5px;
          margin-bottom: 5px;
          position: relative;
          background-color: $color-primary;
          border-radius: 3px;
          z-index: 998;
          transform-origin: 0px 0px;
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0), opacity 0.55s ease;

          &:first-child {
            transform-origin: 0% 0%;
          }

          &:nth-last-child(2) {
            transform-origin: 0% 100%;
          }
        }

        .side-menu {
          position: fixed;
          display: flex;
          flex-direction: column;
          right: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: $color-primary;
          -webkit-font-smoothing: antialiased;
          transform-origin: 0% 0%;
          transform: translate(101%, 0);
          transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
          box-shadow: -1px 1px 1px -1px #000000;
          z-index: 997;

          .site-branding {
            position: fixed;
            top: 0;
            left: 5px;
            overflow: hidden;
            height: 50px;

            .site-branding__logo {
              height: 100%;
            }

            .site-branding__name {
              color: white;
              line-height: 50px;
              font-family: 'Cormorant', sans-serif;
              font-weight: 500;
              font-size: 22px;
              margin: 0;
              padding-left: 5px;
            }
          }

          ul {
            margin: 60px 0 0 5px;
            flex-grow: 1;

            a {
              color: white;
              font-size: 20px;
              font-weight: 500;
              line-height: 48px;
              text-decoration: none;

              li {
                display: block;

                span {
                  display: inline-block;
                  text-align: center;
                  width: 40px;
                }
              }
            }
          }

          .side-menu__copyright {
            margin: auto;
            color: $color-light-text;
            font-size: 14px;
            font-weight: normal;
            align-self: flex-end;
            padding: 20px 0;
          }
        } //end of side-menu

        input {
          display: block;
          width: 40px;
          height: 32px;
          position: absolute;
          top: -7px;
          left: -5px;
          cursor: pointer;
          opacity: 0;
          z-index: 999;

          &:checked ~ span {
            opacity: 1;
            transform: rotate(45deg) translate(-2px, -1px);
            background-color: white;

            &:nth-last-child(2) {
              opacity: 1;
              transform: rotate(-45deg) translate(0, -1px);
            }

            &:nth-last-child(3) {
              opacity: 0;
              transform: rotate(0deg) scale(0.2, 0.2);
            }
          }

          &:checked ~ nav {
            transform: scale(1.0, 1.0);
            opacity: 1;
          }
        }

        @include for-size(phone-only) {
          display: block;
          top: 14px;
          padding: 0 5px;
        }
      }
    }
  }
}
