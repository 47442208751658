/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* =======================================================================
## ++ Media Queries
========================================================================== */
/* =======================================================================
## ++ Unit transform
========================================================================== */
/*  
    Used for making containers have width in percentages. 
    Usage: define elemnt width in px and the width of parent elemnt in px. 
    eg. .block{width:cp(512px, 1024px)} this will result in .block{width:50%;}   
*/
/*  
    Used for making px values convert to rem values
    Usage: define font-size in px and it will convert to rems
    eg. font-size: rem(14px);
*/
/* ===============================================================
Colors
================================================================*/
/* ===============================================================
Responsve media queries
================================================================*/
/* =======================================================================
## ++ Globals
========================================================================== */
body {
  -webkit-font-smoothing: antialiased;
  background-color: #f2f2f2;
  color: #000000;
  font-size: 100%;
  font-family: "Lato", sans-serif;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  color: #374998;
}

@media (max-width: 599px) {
  h1 {
    font-size: 28px;
    margin: 10px 0;
  }
}
@media (min-width: 600px) {
  h1 {
    font-size: 26px;
    margin: 10px 0;
  }
}
@media (min-width: 900px) {
  h1 {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 22px;
  }
}

@media (max-width: 599px) {
  h2 {
    font-size: 24px;
    margin: 10px 0;
  }
}
@media (min-width: 600px) {
  h2 {
    font-size: 28px;
    margin: 10px 0;
  }
}
@media (min-width: 900px) {
  h2 {
    font-size: 24px;
  }
}
@media (min-width: 1200px) {
  h2 {
    font-size: 28px;
  }
}

@media (max-width: 599px) {
  p {
    font-size: 16px;
  }
}
@media (min-width: 600px) {
  p {
    font-size: 12px;
  }
}
@media (min-width: 900px) {
  p {
    font-size: 16px;
  }
}
@media (min-width: 1200px) {
  p {
    font-size: 16px;
  }
}

.inside {
  max-width: 1366px;
  margin: auto;
}
@media (max-width: 599px) {
  .inside {
    padding: 0 5px;
  }
}
@media (min-width: 600px) {
  .inside {
    padding: 0 5px;
  }
}
@media (min-width: 900px) {
  .inside {
    padding: 0 10px;
  }
}
@media (min-width: 1200px) {
  .inside {
    padding: 0 10px;
  }
}

/* =======================================================================
## ++ Clearfix
========================================================================== */
/* float clearing for IE6 */
* html .clearfix {
  height: 1%;
  overflow: visible;
}

/* float clearing for IE7 */
* + html .clearfix {
  min-height: 1%;
}

/* float clearing for everyone else */
.clearfix:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  font-size: 0;
}

.clr {
  clear: both;
}

.social-bar {
  margin: 20px 0;
}
@media (max-width: 599px) {
  .social-bar {
    padding: 5px;
  }
}
@media (min-width: 600px) {
  .social-bar {
    padding: 10px;
  }
}
@media (min-width: 900px) {
  .social-bar {
    padding: 10px 0;
  }
}
.social-bar .social-bar__title {
  font-size: 20px;
  margin: 20px 0;
}
.social-bar .social-bar__items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 599px) {
  .social-bar .social-bar__items {
    flex-direction: column;
  }
}
.social-bar .social-bar__items__item {
  padding: 30px 20px;
  text-align: center;
  width: 20%;
  opacity: 0.9;
  border-radius: 10px;
  text-decoration: none;
}
@media (max-width: 599px) {
  .social-bar .social-bar__items__item {
    background-color: #e0e0e0;
    opacity: 1;
    width: 90%;
    margin: 10px auto;
  }
  .social-bar .social-bar__items__item.facebook > * {
    color: #3d5b99;
  }
  .social-bar .social-bar__items__item.facebook h4 {
    border-bottom: 2px solid #3d5b99;
    opacity: 1;
  }
  .social-bar .social-bar__items__item.instagram > * {
    color: #a97c5e;
  }
  .social-bar .social-bar__items__item.instagram h4 {
    border-bottom: 2px solid #a97c5e;
    opacity: 1;
  }
  .social-bar .social-bar__items__item.linkedin > * {
    color: #0073a4;
  }
  .social-bar .social-bar__items__item.linkedin h4 {
    border-bottom: 2px solid #0073a4;
    opacity: 1;
  }
}
.social-bar .social-bar__items__item:hover {
  background-color: #e0e0e0;
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease;
}
.social-bar .social-bar__items__item.facebook:hover > *, .social-bar .social-bar__items__item.facebook:focus > * {
  color: #3d5b99;
}
.social-bar .social-bar__items__item.facebook:hover h4, .social-bar .social-bar__items__item.facebook:focus h4 {
  border-bottom: 2px solid #3d5b99;
  opacity: 1;
}
.social-bar .social-bar__items__item.instagram:hover > * {
  color: #a97c5e;
}
.social-bar .social-bar__items__item.instagram:hover h4 {
  border-bottom: 2px solid #a97c5e;
  opacity: 1;
}
.social-bar .social-bar__items__item.linkedin:hover > * {
  color: #0073a4;
}
.social-bar .social-bar__items__item.linkedin:hover h4 {
  border-bottom: 2px solid #0073a4;
  opacity: 1;
}
.social-bar .social-bar__items__item i {
  font-size: 60px;
  margin-bottom: 20px;
  color: #aaaaaa;
}
.social-bar .social-bar__items__item h4 {
  color: #aaaaaa;
  opacity: 0.5;
  display: inline-block;
  margin-left: 10px;
  font-weight: 600;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 40px;
}
@media (max-width: 599px) {
  .flex-row {
    flex-direction: column;
  }
}
.flex-row p {
  margin-bottom: 20px;
  padding-right: 20px;
  font-size: 16px;
  line-height: 20px;
}
.flex-row h2 {
  font-size: 22px;
}
.flex-row h3 {
  color: black;
  font-size: 18px;
}
.flex-row .flex-row__item {
  flex: 0 1 auto;
  justify-content: center;
  padding: 20px;
}
@media (max-width: 599px) {
  .flex-row .flex-row__item {
    padding: 10px;
  }
}
.flex-row .flex-row__item.one-half {
  width: 50%;
}
@media (max-width: 599px) {
  .flex-row .flex-row__item.one-half {
    width: 100%;
  }
}
.flex-row .flex-row__item.one-third {
  width: 32%;
}
@media (max-width: 599px) {
  .flex-row .flex-row__item.one-third {
    width: 100%;
  }
}
.flex-row .flex-row__item.two-third {
  width: 65%;
}
@media (max-width: 599px) {
  .flex-row .flex-row__item.two-third {
    width: 100%;
  }
}
.flex-row .flex-row__item img {
  max-height: 100%;
  max-width: 100%;
  vertical-align: center;
}

@media (max-width: 599px) {
  article .content {
    padding: 5px;
  }
}
@media (min-width: 600px) {
  article .content {
    padding: 10px;
  }
}
@media (min-width: 900px) {
  article .content {
    padding: 10px 0;
  }
}

.datagrid table {
  border-collapse: collapse;
  text-align: left;
  width: 100%;
}

.table-title {
  margin: 20px 0;
}

h3.table-info {
  font-size: 14px;
  font-style: italic;
  color: #5a5a5a;
  margin-bottom: 40px;
  margin-left: 5px;
}
h3.table-info .fa {
  color: #322f30;
  font-size: 16px;
}

.datagrid {
  background: #f8f8f8;
  margin: 10px 0;
  border: 1px solid #374998;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.datagrid table td, .datagrid table th {
  padding: 3px 10px;
}

.datagrid table thead th {
  background-color: #374998;
  color: #F8F8F8;
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  border-left: 1px solid #A3A3A3;
}

.datagrid table thead th:first-child {
  border: none;
}

.datagrid table tbody td {
  color: #5e6063;
  border-left: 1px solid #DBDBDB;
  font-size: 16px;
  line-height: 22px;
  font-weight: normal;
}

.datagrid table tbody .alt td {
  background: #EBEBEB;
}

.datagrid table tbody td:first-child {
  border-left: none;
}

.datagrid table tbody tr:last-child td {
  border-bottom: none;
}

@font-face {
  font-family: "Lato";
  src: url(https://fonts.googleapis.com/css?family=Lato);
}
@font-face {
  font-family: "Cormorant";
  src: url("../fonts/cormorant/Cormorant-Light.otf") format("opentype");
}
body.sticky-header header {
  position: fixed;
  top: 0;
  z-index: 900;
  width: 100%;
  background-color: #e4e4e4;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.3s ease;
}

@media (max-width: 599px) {
  header .inside {
    height: 50px;
  }
}
@media (min-width: 600px) {
  header .inside {
    height: 50px;
  }
}
@media (min-width: 900px) {
  header .inside {
    height: 70px;
  }
}
header .site-branding .site-branding__logo {
  float: left;
  height: 70px;
}
@media (max-width: 599px) {
  header .site-branding .site-branding__logo {
    height: 50px;
    padding: 5px 0;
  }
}
@media (min-width: 600px) {
  header .site-branding .site-branding__logo {
    height: 50px;
    padding: 5px 0;
  }
}
@media (min-width: 900px) {
  header .site-branding .site-branding__logo {
    height: 70px;
    padding: 10px 0;
  }
}
header .site-branding .site-branding__name {
  float: left;
  font-family: "Cormorant", sans-serif;
  font-weight: 500;
  margin: 0;
  margin-left: 5px;
}
@media (max-width: 599px) {
  header .site-branding .site-branding__name {
    line-height: 50px;
    font-size: 24px;
  }
}
@media (min-width: 600px) {
  header .site-branding .site-branding__name {
    line-height: 50px;
    font-size: 24px;
  }
}
@media (min-width: 900px) {
  header .site-branding .site-branding__name {
    line-height: 70px;
    padding-left: 5px;
    font-size: 38px;
  }
}
@media (min-width: 1200px) {
  header .site-branding .site-branding__name {
    line-height: 70px;
    padding-left: 5px;
    font-size: 44px;
  }
}
header .site-menu .site-menu__main {
  float: right;
  padding: 0 5px;
}
header .site-menu .site-menu__main ul {
  margin: 0;
  list-style: none;
}
header .site-menu .site-menu__main ul li {
  display: inline-block;
  margin-left: 10px;
  font-weight: 400;
  line-height: 40px;
}
@media (max-width: 599px) {
  header .site-menu .site-menu__main ul li {
    font-size: 14px;
    line-height: 50px;
  }
}
@media (min-width: 600px) {
  header .site-menu .site-menu__main ul li {
    font-size: 14px;
    line-height: 50px;
  }
}
@media (min-width: 900px) {
  header .site-menu .site-menu__main ul li {
    font-size: 20px;
    line-height: 70px;
  }
}
header .site-menu .site-menu__main ul li a {
  text-decoration: none;
  color: #374998;
}
header .site-menu .site-menu__main ul li a:hover {
  color: #156ebb;
}
@media (max-width: 599px) {
  header .site-menu .site-menu__main {
    display: none;
  }
}
header .site-menu .site-menu__side {
  float: right;
}
header .site-menu .site-menu__side .container {
  display: none;
  position: relative;
  height: 25px;
  user-select: none;
}
header .site-menu .site-menu__side .container span {
  display: block;
  width: 33px;
  height: 5px;
  margin-bottom: 5px;
  position: relative;
  background-color: #374998;
  border-radius: 3px;
  z-index: 998;
  transform-origin: 0px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
header .site-menu .site-menu__side .container span:first-child {
  transform-origin: 0% 0%;
}
header .site-menu .site-menu__side .container span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
header .site-menu .site-menu__side .container .side-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #374998;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(101%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  box-shadow: -1px 1px 1px -1px #000000;
  z-index: 997;
}
header .site-menu .site-menu__side .container .side-menu .site-branding {
  position: fixed;
  top: 0;
  left: 5px;
  overflow: hidden;
  height: 50px;
}
header .site-menu .site-menu__side .container .side-menu .site-branding .site-branding__logo {
  height: 100%;
}
header .site-menu .site-menu__side .container .side-menu .site-branding .site-branding__name {
  color: white;
  line-height: 50px;
  font-family: "Cormorant", sans-serif;
  font-weight: 500;
  font-size: 22px;
  margin: 0;
  padding-left: 5px;
}
header .site-menu .site-menu__side .container .side-menu ul {
  margin: 60px 0 0 5px;
  flex-grow: 1;
}
header .site-menu .site-menu__side .container .side-menu ul a {
  color: white;
  font-size: 20px;
  font-weight: 500;
  line-height: 48px;
  text-decoration: none;
}
header .site-menu .site-menu__side .container .side-menu ul a li {
  display: block;
}
header .site-menu .site-menu__side .container .side-menu ul a li span {
  display: inline-block;
  text-align: center;
  width: 40px;
}
header .site-menu .site-menu__side .container .side-menu .side-menu__copyright {
  margin: auto;
  color: #f8f8f8;
  font-size: 14px;
  font-weight: normal;
  align-self: flex-end;
  padding: 20px 0;
}
header .site-menu .site-menu__side .container input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0;
  z-index: 999;
}
header .site-menu .site-menu__side .container input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background-color: white;
}
header .site-menu .site-menu__side .container input:checked ~ span:nth-last-child(2) {
  opacity: 1;
  transform: rotate(-45deg) translate(0, -1px);
}
header .site-menu .site-menu__side .container input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
header .site-menu .site-menu__side .container input:checked ~ nav {
  transform: scale(1, 1);
  opacity: 1;
}
@media (max-width: 599px) {
  header .site-menu .site-menu__side .container {
    display: block;
    top: 14px;
    padding: 0 5px;
  }
}

footer {
  background-color: #322f30;
  box-shadow: 0px -2px 1px 1px rgba(77, 77, 77, 0.95);
  color: #c9c9c9;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-top: 160px;
  padding: 10px 0;
  width: 100%;
}
footer .inside {
  display: flex;
}
@media (max-width: 599px) {
  footer .inside {
    flex-direction: column;
  }
}
@media (min-width: 600px) {
  footer .inside {
    flex-direction: row;
  }
}
@media (max-width: 599px) {
  footer .inside > div {
    width: 100%;
  }
}
@media (min-width: 600px) {
  footer .inside > div {
    width: 50%;
  }
}
@media (min-width: 900px) {
  footer .inside > div {
    width: 33%;
  }
}
footer .footer-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 599px) {
  footer .footer-left {
    display: none;
  }
}
@media (min-width: 600px) {
  footer .footer-left {
    display: none;
  }
}
@media (min-width: 900px) {
  footer .footer-left {
    display: flex;
  }
}
@media (min-width: 1200px) {
  footer .footer-left {
    display: flex;
  }
}
footer .footer-left .footer-menu {
  height: 100%;
}
footer .footer-left .footer-menu ul {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
footer .footer-left .footer-menu ul li {
  display: block;
}
footer .footer-left .footer-menu ul li span {
  display: inline-block;
  text-align: center;
  width: 40px;
}
footer .footer-left .footer-menu ul li a {
  color: #c9c9c9;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-decoration: none;
}
footer .footer-center .information {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}
footer .footer-center .information svg.svg-inline--fa {
  background-color: #464646;
  font-size: 18px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 34px;
  margin: 10px;
  padding: 8px;
  vertical-align: middle;
  float: left;
}
@media (max-width: 599px) {
  footer .footer-center .information svg.svg-inline--fa {
    margin-left: 0;
  }
}
footer .footer-center .information svg.svg-inline--fa.fa-envelope {
  font-size: 16px;
  line-height: 34px;
}
footer .footer-center .information p {
  display: inline-block;
  vertical-align: middle;
  color: #c9c9c9;
  line-height: 52px;
}
footer .footer-center .information p:hover {
  cursor: pointer;
}
footer .footer-center .information p:hover + svg.svg-inline--fa {
  background-color: #374998;
  cursor: pointer;
}
footer .footer-center .information a {
  text-decoration: none;
  color: #c9c9c9;
}
footer .footer-right {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}
footer .footer-right h2 {
  color: #c9c9c9;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 20px;
}
footer .footer-right .footer-education-logo {
  margin: 10px 0;
}
footer .footer-right .footer-education-logo img {
  width: 80px;
}
footer .footer-right .footer-icons {
  display: block;
  margin: 10px 0;
}
footer .footer-right .footer-icons h2 {
  float: left;
  margin: 10px 0 10px;
}
footer .footer-right .footer-icons .social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  line-height: 40px;
}
footer .footer-right .footer-icons .social-icons li a {
  color: #f8f8f8;
}
footer .footer-right .footer-icons .social-icons li a svg.svg-inline--fa {
  background-color: #322f30;
}
footer .footer-copyright {
  color: #8f9296;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin: 10px;
}

article .slider {
  margin-bottom: 40px !important;
}
article .slider .slick-track {
  height: 600px;
}
@media (max-width: 599px) {
  article .slider .slick-track {
    height: 300px;
  }
}
article .slider .slider-item {
  height: 600px;
  background-size: cover;
  background-position: center;
}
@media (max-width: 599px) {
  article .slider .slider-item {
    height: 300px;
  }
}
article .slider .slick-dots {
  bottom: 20px;
}
article .slider .slick-dots li.slick-active {
  opacity: 1;
}
article .slider .slick-dots li button:before {
  color: #374998;
  font-size: 14px;
}
article .carousel-edit .pimcore_editable {
  width: 260px;
  display: inline-block;
  float: left;
}
article .carousel-edit .pimcore_editable img {
  width: 250px;
}