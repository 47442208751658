footer {
    background-color: $color-dark-background;
    box-shadow: 0px -2px 1px 1px rgba(77, 77, 77, 0.95);
    color: #c9c9c9;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    margin-top: 160px;
    padding: 10px 0;
    width: 100%;

    .inside {
        display: flex;

        @include for-size(phone-only) {
            flex-direction: column;
        }

        @include for-size(tablet-portrait) {
            flex-direction: row;
        }

        > div {

            @include for-size(phone-only) {
                width: 100%;
            }

            @include for-size(tablet-portrait) {
                width: 50%;
            }

            @include for-size(tablet-landscape) {
                width: 33%;
            }
        }
    }

    .footer-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include for-size(phone-only) {
            display: none;
        }

        @include for-size(tablet-portrait) {
            display: none;
        }

        @include for-size(tablet-landscape) {
            display: flex;
        }

        @include for-size(desktop) {
            display: flex;
        }

        .footer-menu {
            height: 100%;

            ul {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                height: 100%;

                li {
                    display: block;

                    span {
                        display: inline-block;
                        text-align: center;
                        width: 40px;
                    }

                    a {
                        color: #c9c9c9;
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 32px;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    // End of footer-left

    .footer-center {

        .information {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;

            svg.svg-inline--fa {
                background-color: #464646;
                font-size: 18px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                text-align: center;
                line-height: 34px;
                margin: 10px;
                padding: 8px;
                vertical-align: middle;
                float: left;

                @include for-size(phone-only) {
                    margin-left: 0;
                }

                &.fa-envelope {
                    font-size: 16px;
                    line-height: 34px;
                }
            }

            p {
                display: inline-block;
                vertical-align: middle;
                color: #c9c9c9;
                line-height: 52px;

                &:hover {
                    cursor: pointer;
                }

                &:hover + svg.svg-inline--fa {
                    background-color: $color-primary;
                    cursor: pointer;
                }
            }

            a {
                text-decoration: none;
                color: #c9c9c9;
            }
        }

    }

    // End of footer-center

    .footer-right {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;

        h2 {
            color: #c9c9c9;
            margin-bottom: 10px;
            font-weight: 300;
            font-size: 20px;
        }

        .footer-education-logo {
            margin: 10px 0;

            img {
                width: 80px;
            }
        }

        .footer-icons {
            display: block;
            margin: 10px 0;

            h2 {
                float: left;
                margin: 10px 0 10px;
            }

            .social-icons {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                width: 50%;
                line-height: 40px;

                li {

                    a {
                        color: $color-light-text;

                        svg.svg-inline--fa {
                            background-color: #322f30;
                        }
                    }
                }
            }
        }
    }

    .footer-copyright {
        color: $color-dark-text;
        font-size: 14px;
        font-weight: normal;
        text-align: center;
        margin: 10px;
    }
}
